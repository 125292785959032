import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "packet-actions"
    }}>{`Packet Actions`}</h1>
    <p><strong parentName="p">{`POST`}</strong>{` `}<inlineCode parentName="p">{`packetActionCallbackUrl`}</inlineCode></p>
    <p>{`Packet Actions `}<inlineCode parentName="p">{`POST`}</inlineCode>{` to the `}<inlineCode parentName="p">{`packetActionCallbackUrl`}</inlineCode>{` set in the New Packet data.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <p>{`The `}<strong parentName="p">{`Packet Action`}</strong>{` webhook request parameters are formatted as shown below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Action_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Integer. The Packet_Action_Id will be one of the following values: 501001, 501002, 503001, 503006, 503007, 503008, 503005, 503002, 503003, 503004`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. A unique ID for each packet. Every action callback will contain a Packet_Id.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. This is the My_Custom_Fields array provided to SPF in the packet definition when the packet was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Document_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. If this action pertains to a document in the packet, then this will be the code from the document of interest.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Document_My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. If this action pertains to a document in the packet, then this is the My_Custom_Fields array associated with that same document and provided to SPF in the packet definition when the packet was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Person_Id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. String. If this action pertains to a person in the packet, then this will be the code from the person of interest.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet_Person_My_Custom_Fields`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Array. If this action pertains to a person in the packet, then this is the My_Custom_Fields array associated with that same person and provided to SPF in the packet definition when the packet was created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UTC_DateTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. DateTime. The UTC DateTime this action occurred.`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h4 {...{
      "id": "packet-actions-reference"
    }}>{`Packet Actions Reference`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet Created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Packet Signing Completed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SPF Sent Message to View Documents`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503006`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PIN Sent`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503007`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PIN Validated`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503008`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PIN Validation Attempt Failed`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503005`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attempt to View Documents Failed`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Person Viewed Documents`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503003`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Person Signed Documents`}{`*`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503004`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SPF Sent Message with Signed Documents`}{`*`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` Request data will include a Packet_Person_Id.`}</p>
    <p>{`^ Request data will include a Packet_Document_Id.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Packet Action`}</strong>{` webhook requests will always send the `}<em parentName="p">{`packet_action_id`}</em>{`, `}<em parentName="p">{`packetId`}</em>{`, and `}<em parentName="p">{`UTCDateTime`}</em>{`.`}</p>
    <p>{`If `}<inlineCode parentName="p">{`My_Custom_Fields`}</inlineCode>{` was set in the New Packet data, the field "Packet_My_Custom_Fields" will be added to the webhook request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Packet_Action_Id": integer,      (required)
  "Packet_Id": string,            (required)
  "UTC_DateTime": datetime,         (required)
  "Packet_My_Custom_Fields": array,
}
`}</code></pre>
    <p>{`If the action pertains to a `}<strong parentName="p">{`document`}</strong>{` in the packet, additional fields will be added to the webhook request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Packet_Action_Id": integer,               (required)
  "Packet_Id": string,                     (required)
  "UTC_DateTime": datetime,                  (required)
  "Packet_Document_Id": string,
  "Packet_Document_My_Custom_Fields": array,
}
`}</code></pre>
    <p>{`If the action pertains to a `}<strong parentName="p">{`person`}</strong>{` in the packet, additional fields will be added to the webhook request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Packet_Action_Id": integer,               (required)
  "Packet_Id": string,                     (required)
  "UTC_DateTime": datetime,                  (required)
  "Packet_Person_Id": string,
  "Packet_Person_My_Custom_Fields": associative array,
}
`}</code></pre>
    <hr></hr>
    <p><strong parentName="p">{`Example Webhook Request`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`POST https://website.com/callback/packet_action
{
  "Packet_Action_Id": "501001",
  "Packet_Id": "57470dd9-bb69-4539-8b7b-6c6909634291",
  "Packet_My_Custom_Fields": {
    "callbackAuthKey": "Az8ZK6W7tMm5JBX639xiAsm12^Lp2j"
  },
  "UTC_DateTime": "2021-05-31 09:00:00"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      